// const initialState = {
//   user: JSON.parse(localStorage.getItem('user')),
//   clientSession: JSON.parse(localStorage.getItem('clientSession')),
//   sessionHash: JSON.parse(localStorage.getItem('sessionHash')),
//   unknown: JSON.parse(localStorage.getItem('unknown')),
//   iamclient: JSON.parse(localStorage.getItem('iamclient')),
//   invoiceId: JSON.parse(localStorage.getItem('invoiceId')) || '0',
//   invoiceData: JSON.parse(localStorage.getItem('invoiceData')) || '0',
//   orderId: JSON.parse(localStorage.getItem('orderId')) || '0',
//   cartProducts: JSON.parse(localStorage.getItem('cartProducts')) || null,
//   totalProducts: JSON.parse(localStorage.getItem('totalProducts')) || '0',
//   orders: JSON.parse(localStorage.getItem('orders')),
//   dataStore: JSON.parse(localStorage.getItem('dataStore')),
//   dataStore2: JSON.parse(localStorage.getItem('dataStore2')),
//   catalogProducts: [],
//   configData: JSON.parse(localStorage.getItem('configData')),
// }

// Safe JSON parsing helper
const safeParse = (key, defaultValue = null) => {
  try {
    const value = localStorage.getItem(key)
    return value ? JSON.parse(value) : defaultValue
  } catch (e) {
    console.error(`Error parsing ${key} from localStorage:`, e)
    return defaultValue
  }
}

const initialState = {
  user: safeParse('user'),
  clientSession: safeParse('clientSession'),
  sessionHash: safeParse('sessionHash'),
  unknown: safeParse('unknown'),
  iamclient: safeParse('iamclient'),
  invoiceId: safeParse('invoiceId', '0'),
  invoiceData: safeParse('invoiceData', '0'),
  orderId: safeParse('orderId', '0'),
  cartProducts: safeParse('cartProducts', null),
  totalProducts: safeParse('totalProducts', '0'),
  orders: safeParse('orders'),
  dataStore: safeParse('dataStore'),
  dataStore2: safeParse('dataStore2'),
  catalogProducts: [],
  configData: safeParse('configData'),
}

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_DATA_STORE':
      let data = action.payload
      localStorage.setItem('dataStore', JSON.stringify(data))

      return {
        ...state,
        dataStore: data,
      }
    case 'SET_DATA_STORE_2':
      let data2 = action.payload
      localStorage.setItem('dataStore2', JSON.stringify(data2))

      return {
        ...state,
        dataStore2: data2,
      }
    case 'SET_CONFIG':
      let config = action.payload
      localStorage.setItem('configData', JSON.stringify(config))

      return {
        ...state,
        configData: config,
      }
    case 'LOG_IN':
      localStorage.setItem('user', JSON.stringify(action.payload))
      localStorage.setItem('iamclient', JSON.stringify('1'))
      localStorage.setItem('unknown', JSON.stringify('0'))
      localStorage.setItem('sessionHash', JSON.stringify(''))
      return {
        ...state,
        user: action.payload,
        iamclient: '1',
        unknown: '0',
      }
    case 'CLIENT_SESSION':
      localStorage.setItem(
        'clientSession',
        JSON.stringify(action.payload.clientId)
      )
      localStorage.setItem(
        'sessionHash',
        JSON.stringify(action.payload.sessionId)
      )
      localStorage.setItem('unknown', JSON.stringify(action.payload.unknown))
      return {
        ...state,
        clientSession: action.payload.clientId,
        sessionHash: action.payload.sessionId,
        unknown: action.payload.unknown,
      }
    case 'LOG_OUT':
      localStorage.removeItem('user')
      localStorage.setItem('iamclient', JSON.stringify('0'))
      localStorage.setItem('unknown', JSON.stringify('1'))
      return {
        ...state,
        user: null,
      }
    case 'SET_PRODUCTS_CART':
      let products = []
      if (action.payload) {
        Object.keys(action.payload).forEach((key) => {
          let product = action.payload[key]
          let existProduct = products.find((p) => p.sku === product.sku)
          if (existProduct) {
            existProduct.quantity = +existProduct.quantity + +product.quantity
          } else {
            products.push(product)
          }
        })
      }
      localStorage.setItem('cartProducts', JSON.stringify(products))
      return {
        ...state,
        cartProducts: products,
      }
    case 'SET_CATALOG_PRODUCTS':
      return {
        ...state,
        catalogProducts: action.payload,
      }
    case 'SET_INVOICE_ID':
      localStorage.setItem('invoiceId', JSON.stringify(action.payload))
      return {
        ...state,
        invoiceId: action.payload,
      }
    case 'SET_TOTAL_PRODUCTS':
      localStorage.setItem('totalProducts', JSON.stringify(action.payload))
      return {
        ...state,
        totalProducts: action.payload,
      }
    case 'SET_CALCULATE_INVOICE':
      localStorage.setItem('invoiceData', JSON.stringify(action.payload))
      return {
        ...state,
        invoiceData: action.payload,
      }
    case 'DELETE_PRODUCT':
      return {
        ...state,
        carProducts: state.carProducts.filter(
          (p) => p.product.id !== action.payload
        ),
      }
    case 'DELETE_CAR_PRODUCTS':
      return {
        ...state,
        carProducts: [],
      }
    case 'SET_PAYMENT_DATA':
      localStorage.setItem('MPdata', JSON.stringify(action.payload))
      break
    case 'SET_ORDERS':
      let ordersFixed = Object.keys(action.payload).map(
        (key) => action.payload[key]
      )
      localStorage.setItem('orders', JSON.stringify(ordersFixed))
      return {
        ...state,
        orders: ordersFixed,
      }
    default:
      return state
  }
}
