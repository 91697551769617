import React, { useEffect, useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

// COMPONENTS
import Home from './components/Home'
import NavBar from './components/NavBar'
import LogIn from './components/LogIn'
import SignUp from './components/SignUp'
import RecoverPassword from './components/RecoverPassword'
import MyOrders from './components/MyOrders'
import OrderDetail from './components/OrderDetail'
import ProductDetail from './components/ProductDetail'
import Cart from './components/Cart'
import Checkout from './components/Checkout'
import ProductsView from './components/ProductsView'
import Categories from './components/Categories'
import Brands from './components/Brands'
import Stores from './components/Stores'
import Footer from './components/Footer'
import FooterInfo from './components/FooterInfo'
import StatePurchase from './components/StatePurchase'

import 'tailwindcss/tailwind.css'

import {
  getDataStore,
  getDataStore2,
  obtenterClientSession,
  getConfigStore,
} from './methods/index'

import {
  setDataStore,
  setDataStore2,
  setConfig,
  setClientSession,
} from './actions/index'
import EditProfile from './components/EditProfile'
import { Helmet } from 'react-helmet'

import { HeadProvider, Title, Link, Meta } from 'react-head';
import Messages from './components/Messages'

export default function App() {

  let dispatch = useDispatch()
  let clientSession = useSelector((state) => state.clientSession)
  let dataStore = useSelector((state) => state.dataStore)
  let configData = useSelector((state) => state.configData)
  const user = useSelector(state => state.user);

  //f shared state with NavBar
  const [showLanding, setShowLanding] = useState(false);

  const location = useLocation();

  const hideLanding = () => {
    setShowLanding(false);
  };

  //f resets showLanding when user logs in or when route changes
  useEffect(() => {
    if (user || location.pathname !== '/') {
      setShowLanding(false);
    }
  }, [user, location.pathname]);

  //f shows landing only on home page for non-logged in users
  // useEffect(() => {
  //   if (location.pathname === '/' && !user) {
  //     setShowLanding(true);
  //   }
  // }, [location.pathname, user]);

  useEffect(() => {
    if (location.pathname === '/' && !user && dataStore?.ecommerce_info2 !== "0") {
      setShowLanding(true);
    } else {
      setShowLanding(false);
    }
  }, [location.pathname, user, dataStore?.ecommerce_info2]);

  //f ---
  // useEffect(() => {
  //   ;(async () => {
  //     let splitWith = window.location.href.includes('.com.ar')
  //       ? '.com.ar'
  //       : '.com'
  //     let url = window.location.href.split(splitWith)[0] + splitWith
  //     const config = await getConfigStore(url)
  //     dispatch(setConfig(config))
  //   })()
  // }, [])

  //f ---
  // useEffect(() => {
  //   ;(async () => {
  //     if (configData) {
  //       const data = await getDataStore()
  //       const data2 = await getDataStore2()
  //       dispatch(setDataStore(data))
  //       dispatch(setDataStore2(data2))
  //       if (!clientSession) {
  //         dispatch(setClientSession(await obtenterClientSession()))
  //       }
  //     }
  //   })()
  // }, [configData])

  //f new ordered useEffect() first calls getConfigStore to get api_key and storeid

  useEffect(() => {
    (async () => {
      // Step 1: Fetch configuration (api_key and storeId)
      let splitWith = window.location.href.includes('.com.ar') ? '.com.ar' : '.com'
      let url = window.location.href.split(splitWith)[0] + splitWith
      const config = await getConfigStore(url)
      dispatch(setConfig(config))
  
      // Step 2: Ensure configData is available
      if (config) {
        const data = await getDataStore()
        const data2 = await getDataStore2()
        dispatch(setDataStore(data))
        dispatch(setDataStore2(data2))
  
        // Step 3: Initialize client session if needed
        if (!clientSession) {
          const session = await obtenterClientSession()
          dispatch(setClientSession(session))
        }
      }
    })()
  }, [])
  
  //! remove this cause it triggers at every render
  // useEffect(() =>{
  //   localStorage.removeItem('configData')
  //   localStorage.removeItem('dataStore')
  //   localStorage.removeItem('dataStore2')
  // },[])

  //f safety check
  // let colores = JSON.parse(localStorage.getItem('dataStore')) || ''
  let colores = {}
try {
  const storedData = localStorage.getItem('dataStore')
  colores = storedData ? JSON.parse(storedData) : {}
} catch (e) {
  console.error('Error parsing dataStore from localStorage:', e)
  colores = {}
}

//f conditional loading until critical data is ready
if (!dataStore || !configData) {
  return <div className="h-screen flex items-center justify-center">Loading...</div>
}

  return (
      <div className="font-roboto" style={{
      backgroundColor: colores.ecommerce_colorSecundario
      }}>
      <Helmet>
        <title>{dataStore?.ecommerce_mtatitle}</title>
        <meta name='description' content={dataStore?.ecommerce_mtadesc} />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${dataStore?.ecommerce_analytics}`}
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());

            gtag("config", "${dataStore?.ecommerce_analytics}");
          `}
        </script>
      </Helmet>
      <NavBar showLanding={showLanding}/>
      <Routes>
        <Route path={'/:actualPage?'} element={<Home         
        showLanding={showLanding}
        setShowLanding={setShowLanding}
        hideLanding={hideLanding} />} />
        <Route path={'/estado-compra/core/cart/'} element={<StatePurchase />} />
        <Route path={'/:productName/:page'} element={<Home/>} />
        <Route path={'/iniciarsesion'} element={<LogIn />} />
        <Route path={'/registrarse'} element={<SignUp />} />
        <Route path={'/recuperar-contraseña'} element={<RecoverPassword />} />
        <Route path={'/editar-perfil'} element={<EditProfile />} />
        <Route path={'/mis-pedidos'} element={<MyOrders />} />
        <Route path={'/mis-pedidos/:orderId'} element={<OrderDetail />} />
        <Route path={'/producto/:name/:id'} element={<ProductDetail />} />
        <Route path={'/carrito'} element={<Cart />} />
        <Route path={'/checkout'} element={<Checkout />} />
        <Route path={'/mensajes'} element={<Messages />} />
        <Route
          path={'/productos/:categoryOrBrand/:name/:id/:actualPage'}
          element={<ProductsView />}
        />
         <Route
          path={'/productos/:categoryOrBrand/:name/:subCategory/:id/:idSubCategory/:actualPage'}
          element={<ProductsView />}
        />
        <Route path={'/categorias/:actualPage'} element={<Categories />} />
        <Route path={'/marcas/:actualPage'} element={<Brands />} />
        <Route path={'/tiendas'} element={<Stores />} />
        <Route path={'/footer/:title'} element={<FooterInfo />} />
      </Routes>
      <Footer />
    </div>
  )
}
